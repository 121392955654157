<template>
  <v-dialog v-model="dialog" width="500px" height="80vh">
    <v-card min-height="40vh">
      <v-card-title>Add a discount voucher for this user</v-card-title>

      <v-card-text>
        <v-form ref="voucherForm" lazy-validation>
          <v-row>
            <v-col cols="8" sm="8" md="8">
              <vx-input v-model="code" type="text" name="Code" placeholder="e.g. QESCWZ8E" required />
            </v-col>
            <v-col cols="4" sm="4" md="4">
              <vx-btn
                block
                color="primary"
                :loading="loading.voucher"
                :disabled="!code"
                @click="$validate(addVoucher, null, 'voucherForm')"
              >
                Add
              </vx-btn>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>

      <v-divider class="mx-4"></v-divider>

      <v-card-title>Add credits to gift card for this user</v-card-title>

      <v-card-text>
        <v-form ref="voucherForm" lazy-validation>
          <v-row no-gutters>
            <v-col cols="6" sm="6" md="6">
              <span class="text-subtitle-1">gift card: </span>
              <span v-if="loading.gift"><v-icon> mdi-loading mdi-spin </v-icon></span>
              <span v-else>{{ giftCode }}</span>
            </v-col>

            <v-col cols="6" sm="6" md="6">
              <span class="text-subtitle-1">Available Credits: </span>
              <span v-if="loading.gift"><v-icon> mdi-loading mdi-spin </v-icon></span>
              <span v-else>{{ giftBalance | price }}</span>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="8" sm="8" md="8">
              <vx-input v-model="credits" type="number" name="Credits" prefix="£" required />
            </v-col>
            <v-col cols="4" sm="4" md="4">
              <vx-btn
                block
                color="primary"
                :loading="loading.credit"
                :disabled="!credits"
                @click="$validate(addCredits, null, 'voucherForm')"
              >
                Add
              </vx-btn>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>

      <v-card-actions class="pt-0">
        <v-row no-gutters>
          <v-col cols="6" sm="6" md="6" offset="3">
            <vx-btn block color="tertiary" @click="dialog = false">Cancel</vx-btn>
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { VoucherService } from '@tutti/services';

export default {
  name: 'DiscountDialog',
  props: {
    account: { type: Object, required: true },
    value: { type: Boolean, required: true },
  },
  data() {
    return {
      dialog: this.value,
      code: null,
      credits: null,
      loading: {
        voucher: false,
        credit: false,
        gift: false,
      },
      giftCode: '',
      giftBalance: '',
    };
  },
  watch: {
    value(newValue) {
      this.dialog = newValue;
    },
    dialog(newValue) {
      this.$emit('input', newValue);
    },
  },
  async mounted() {
    await this.getGiftcard();
  },
  methods: {
    async getGiftcard() {
      this.loading.gift = true;

      const response = await VoucherService.getUserVouchers(this.account._id);
      if (response) {
        this.giftCode = this.$get(response, 'data.gift[0].code') || 'N/A';
        this.giftBalance = this.$get(response, 'data.gift[0].gift.balance') || 0;
      }

      this.loading.gift = false;
    },
    async addVoucher() {
      this.loading.voucher = true;

      if (this.code) {
        const response = await VoucherService.addVoucher({
          code: this.code,
          userId: this.account._id,
        });

        if (response) {
          this.dialog = false;
          this.$bus.$emit('resolve');
        }
      }

      this.loading.voucher = false;
    },
    async addCredits() {
      this.loading.credit = true;

      const response = await VoucherService.addCredits({
        credits: this.credits * 100,
        userId: this.account._id,
      });

      if (response) {
        this.dialog = false;
        this.$bus.$emit('resolve');
      }

      this.loading.credit = false;
    },
  },
};
</script>
