var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-dialog', {
    attrs: {
      "width": "500px",
      "height": "80vh"
    },
    model: {
      value: _vm.dialog,
      callback: function callback($$v) {
        _vm.dialog = $$v;
      },
      expression: "dialog"
    }
  }, [_c('v-card', {
    attrs: {
      "min-height": "40vh"
    }
  }, [_c('v-card-title', [_vm._v("Add a discount voucher for this user")]), _c('v-card-text', [_c('v-form', {
    ref: "voucherForm",
    attrs: {
      "lazy-validation": ""
    }
  }, [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": "8",
      "sm": "8",
      "md": "8"
    }
  }, [_c('vx-input', {
    attrs: {
      "type": "text",
      "name": "Code",
      "placeholder": "e.g. QESCWZ8E",
      "required": ""
    },
    model: {
      value: _vm.code,
      callback: function callback($$v) {
        _vm.code = $$v;
      },
      expression: "code"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "4",
      "sm": "4",
      "md": "4"
    }
  }, [_c('vx-btn', {
    attrs: {
      "block": "",
      "color": "primary",
      "loading": _vm.loading.voucher,
      "disabled": !_vm.code
    },
    on: {
      "click": function click($event) {
        return _vm.$validate(_vm.addVoucher, null, 'voucherForm');
      }
    }
  }, [_vm._v(" Add ")])], 1)], 1)], 1)], 1), _c('v-divider', {
    staticClass: "mx-4"
  }), _c('v-card-title', [_vm._v("Add credits to gift card for this user")]), _c('v-card-text', [_c('v-form', {
    ref: "voucherForm",
    attrs: {
      "lazy-validation": ""
    }
  }, [_c('v-row', {
    attrs: {
      "no-gutters": ""
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "6",
      "sm": "6",
      "md": "6"
    }
  }, [_c('span', {
    staticClass: "text-subtitle-1"
  }, [_vm._v("gift card: ")]), _vm.loading.gift ? _c('span', [_c('v-icon', [_vm._v(" mdi-loading mdi-spin ")])], 1) : _c('span', [_vm._v(_vm._s(_vm.giftCode))])]), _c('v-col', {
    attrs: {
      "cols": "6",
      "sm": "6",
      "md": "6"
    }
  }, [_c('span', {
    staticClass: "text-subtitle-1"
  }, [_vm._v("Available Credits: ")]), _vm.loading.gift ? _c('span', [_c('v-icon', [_vm._v(" mdi-loading mdi-spin ")])], 1) : _c('span', [_vm._v(_vm._s(_vm._f("price")(_vm.giftBalance)))])])], 1), _c('v-row', [_c('v-col', {
    attrs: {
      "cols": "8",
      "sm": "8",
      "md": "8"
    }
  }, [_c('vx-input', {
    attrs: {
      "type": "number",
      "name": "Credits",
      "prefix": "£",
      "required": ""
    },
    model: {
      value: _vm.credits,
      callback: function callback($$v) {
        _vm.credits = $$v;
      },
      expression: "credits"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "4",
      "sm": "4",
      "md": "4"
    }
  }, [_c('vx-btn', {
    attrs: {
      "block": "",
      "color": "primary",
      "loading": _vm.loading.credit,
      "disabled": !_vm.credits
    },
    on: {
      "click": function click($event) {
        return _vm.$validate(_vm.addCredits, null, 'voucherForm');
      }
    }
  }, [_vm._v(" Add ")])], 1)], 1)], 1)], 1), _c('v-card-actions', {
    staticClass: "pt-0"
  }, [_c('v-row', {
    attrs: {
      "no-gutters": ""
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "6",
      "sm": "6",
      "md": "6",
      "offset": "3"
    }
  }, [_c('vx-btn', {
    attrs: {
      "block": "",
      "color": "tertiary"
    },
    on: {
      "click": function click($event) {
        _vm.dialog = false;
      }
    }
  }, [_vm._v("Cancel")])], 1)], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }